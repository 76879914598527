import styled from '@emotion/styled';
import React, { createContext, useContext, useState, FC } from 'react';
import { Form } from 'semantic-ui-react';

const DbRadioContainer = styled.span<{ selected?: boolean }>`
  display: block;
  font-size: 14px;
  background-color: ${(props) => (props.selected ? 'yellow' : 'gray')};
  background-position: 2px;
  padding: 5px;
  border-radius: 5px 5px 5px;
  border: 1px solid black;
  padding-left: 25px;
  position: relative;
`;

const INITIAL_DB = 'prod';

const DbContext = createContext(null);

export const DbProvider: FC = ({ children }) => {
  const [db, setDb] = useState(INITIAL_DB);
  // make sure other components can read this value
  return (
    <DbContext.Provider value={{ db, setDb }}>{children}</DbContext.Provider>
  );
};

export const DbSwitcher = () => {
  const dbContext = useContext(DbContext);
  return (
    <Form>
      <Form.Group grouped>
        <DbRadioContainer selected={dbContext.db === 'prod'}>
          <Form.Radio
            label="PRODUCTION"
            value="prod"
            checked={dbContext.db === 'prod'}
            onChange={(_evt, data) => dbContext.setDb(data.value as string)}
          />
        </DbRadioContainer>
        <DbRadioContainer selected={dbContext.db === 'dev'}>
          <Form.Radio
            label="SANDBOX"
            value="dev"
            checked={dbContext.db === 'dev'}
            onChange={(_evt, data) => dbContext.setDb(data.value as string)}
          />
        </DbRadioContainer>
      </Form.Group>
    </Form>
  );
};

export default DbContext;
