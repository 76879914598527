import 'semantic-ui-css/semantic.min.css';
import React from 'react';
import Amplify from 'aws-amplify';
import awsConfig from './src/aws-exports';
import { UserProvider } from './src/components/UserContext';
import { DbProvider } from './src/components/DbContext';

Amplify.configure(awsConfig);

export const wrapRootElement = ({ element }) => (
  <UserProvider>
    <DbProvider>{element}</DbProvider>
  </UserProvider>
);
