import React, { createContext, FC, useEffect, useState } from 'react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';

// originally the user is going to be `null`
const UserContext = createContext(null);

export const UserProvider: FC = ({ children }) => {
  const [user, setUser] = useState(null);

  // fetch the info of the user that may be already logged in
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((authUser) => setUser(authUser))
      .catch(() => setUser(null));
  }, []);

  // track changes to the auth user
  useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      if (nextAuthState === AuthState.SignedIn) {
        setUser(authData);
      } else {
        setUser(null);
      }
    });
  }, []);
  // make sure other components can read this value
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export default UserContext;
