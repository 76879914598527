// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-debug-tab-tsx": () => import("./../../../src/pages/index/DebugTab.tsx" /* webpackChunkName: "component---src-pages-index-debug-tab-tsx" */),
  "component---src-pages-index-export-tab-tsx": () => import("./../../../src/pages/index/ExportTab.tsx" /* webpackChunkName: "component---src-pages-index-export-tab-tsx" */),
  "component---src-pages-index-timeseries-tab-tsx": () => import("./../../../src/pages/index/TimeseriesTab.tsx" /* webpackChunkName: "component---src-pages-index-timeseries-tab-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */)
}

